import React, { useState, useRef } from "react"
import { message } from "antd"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { Select} from "antd"
import { sentContact } from "../../providers/sendContact"

import { Button02 } from "../Button02"

import "antd/dist/antd.css"
import "../../assets/css/global.css"
import { Link } from "gatsby"
import { postForm } from "../../providers/formProvider"


const key = "updatable"

const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 4.125rem auto 0;
  padding: 0 3.125em;
  margin-top: 100px;
  margin-bottom: 3em;
  display: flex;
  flex-wrap: wrap;

                .texthead{
                  color: #292929;
                  font-size: 40px;
                  font-weight: 800;
                }

  @media (max-width: 900px) {
    margin-top: 60px;
    margin-bottom: 60px;
    

    .texthead{
      font-size: 30px;
  }}

  @media (max-width: 500px) {
    margin-top: 60px;
    margin-bottom: 30px;
    padding: 0 1em;/**********/

    .texthead{
      font-size: 26px;
      width: max-content;
  }}
`

const ContentLink = styled(Link)`
  display: flex;
  font-size: 0.6em;
  margin: 0 1em;
  :hover{
    border: none;
  color: #FFFFFF;
  transform: scale(1.1);
  will-change: transform;

  }
`
const Selectstyled = styled(Select)`
    width: 100%;
    height: 58px;
    border-radius: 50px;
    border: 5px solid #0044B0;
    padding: 0px 1.5em;
    margin-bottom: 18px;
    display:flex;
    align-items: center;
  .ant-select-selector {
    border: 0px solid #FFFFFF !important;
    height: 48px !important;
    display:flex !important;
    align-items: center !important;
  }

  .ant-select-open {
    border: 0px solid #FFFFFF!important;
    height: 48px !important;
    display:flex !important;
    align-items: center !important;
  }

  .ant-select-focused {
    border: 0px solid #FFFFFF !important;
    height: 48px !important;
    display:flex !important;
    align-items: center !important;
  }

.ant-select-selection-placeholder{
  color: #292929 !important;
  font-size: 16px !important;
}
  
  @media (max-width: 500px) {
    border: 3px solid #0044B0;

  }
`

const FirstBlock=styled.div`
width: 1440px;
display: flex;
position: relative;
flex-direction: row;

.content-address {
    width: 50%;
    color: #000;
    font-size: 18px;
                    img{
                      width: 40px;
                      margin-right: 1em;
                    }
                    .img2{
                      width: 30px;
                      margin-right:50px;
                    }
                    .box{
                      color: #292929;
                      margin: 1em 0;
                    }
                    .boxw,
                    a{
                      color: #292929 ;
                      margin: 1em 0;
                            :hover {
                              color:#0044B0;
                              font-weight: 500;
                              border: none;
                              will-change: transform;
                                    img{
                                    border: none;
                                    color: #FFFFFF;
                                    transform: scale(1.1);
                                    will-change: transform;}
                                    }
                    }
  }
.content-siguenos{
    position: relative;
    width: 9%;
    flex-direction: row;
    font-size: 1em;
    display: flex;
    justify-content: center;
    padding: 10px 0 30px 0;
    margin-left: 9em;
                    .link {
                      width: 100%;
                    }
                    .pretext{
                      align-self: center;
                      margin-right: 10px;
                      margin-left: 2px;
                    }
}
.content-form {
    width: 50%;
    padding-right: 50px;
                    .form {
                      margin-top: 1em;
                      display: flex;
                      flex-direction: column;
                      align-content: space-between;
                            .content-text {}
                    }
                    label {
                      display: block;
                      font-family: Montserrat;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 16px;
                      color: #3374ba;
                    }
                    .input-error {
                      border-color: #ff4d4f;
                    }
                    .centered{
                      margin: 0 10em;
                      display: flex;
                      flex-direction: column;
                      align-items: center;

                    }
                    input,
                    textarea {
                      width: 100%;
                      border-radius: 30px;
                      border: 5px solid #0044B0;
                      padding: 1em 1.5em;
                      margin-bottom: 18px;
                    }
                    input {
                      height: 36px;
                      border-radius: 50px;
                      border: 5px solid #0044B0;
                      padding: 1.5em 1.5em;
                      margin-bottom: 18px;
                    }
                    select{
                      width: 100%;
                      height: 58px;
                      border-radius: 50px;
                      border: 5px solid #0044B0;
                      padding: 0px 1.5em;
                      margin-bottom: 18px;
                    }
                    option{
                      min-height: 1.5rem !important;
  padding: 14px !important;
                    }
                    textarea {
                      height: 80px;
                    }
                    .loading,.done{
                      position:relative;
                      font-size: 16px;
                      color: #0044B0;
                      font-weight: 700;
                      display: flex;
                      justify-content: flex-start;
                      margin-top: 1em;

                    }
  }

@media (max-width: 900px) {
  flex-direction: column;
  min-width: 400px;
  margin-bottom: 2em;
  .content-address{ width: 100%}
  .content-form{
    width: 90%;
    padding: 0  10% 0 0; 
                .centered{
                  display: flex;
                  justify-content: center;
                  margin: 0;
                }}
              }
 @media (max-width: 500px) {
  flex-direction: column;
  min-width: 300px;
  margin-bottom: 2em;
  .content-address{ 
    padding-left: 5%;
    width: 100%;
    font-size: 14px;
    .box.boxw{
      width: min-content;
    }
  }
  .content-form{

    width: 95%;
    padding: 0  0 0 5%; 
                .centered{
                  display: flex;
                  justify-content: center;
                  margin: 0;
                }
                input, textarea,select{
                  border: 3px solid #0044B0;
                }
              }
              } 
@media (max-width:376px){
  max-width: 375px;
  .content-address{
    padding: 0;
    width: 90%;
    .img2{
      width: 20px;
    }
  }
  .content-form{
    padding: 0;
    width: 90%;

  }
}           

`
const SecondBlock=styled.div`
max-width: 1440px;
display: flex;
position: relative;
flex-direction: column;

      iframe{
        justify-self: flex-start;
      }
      .textquote{
        position: relative;
        color: #0044B0;
        font-size: 60px;
        font-weight: 800;
        font-style: italic;
        text-align: center;
        line-height: 110%;
        padding: 1.5em 3em;
        
      }  
@media (max-width: 900px) {
  min-width: 300px;
  padding: 0 2%;
        iframe{
          width: -webkit-fill-available;
          height: max-content;
        }
        .textquote{
          font-size: 26px;
          padding: 0;
          margin-top: 1em;
        }
}
`


const ContactFormInfo = ({ data, courses }) => {
const [message , setMessage] = useState('');
const [name , setName] = useState('');
const [cellphone, setCellphone ]= useState('');
const [curso,setCurso]=useState(undefined);

const nameref=useRef();
const whatsappref=useRef();
const mensajeref=useRef();
const selectref=useRef();

const [showOk,setShowOk]=useState(false);
const [showLoading, setShowLoading]=useState(false);

const submit = async() => {

  setShowLoading(true);
try{
      const body={
            name: name,
            message: message,
            whatsapp: cellphone,
            course: curso ,
          }
   
      const response = await postForm(body);
  
nameref.current.value='';
whatsappref.current.value='';
mensajeref.current.value='';
setCurso(null);

setShowLoading(false);
     
  setShowOk(true);

  setTimeout(()=>{
  setShowOk(false);
    }, 60000);
  
}
catch(error){
  console.log('error',error)
}
}

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()


  const handleChange = (value) => {
    
   
    setCurso(value)

    
  };

  {/*const onSubmit = async (formData, e) => {
    try {
      const response = await sentContact(formData)
      message.loading({ content: "Enviando", key })
      setTimeout(() => {
        message.success({
          content: response.detail[0],
          key,
          duration: 3,
        })
        e.target.reset()
      }, 1000)
    } catch (error) {
      message.loading({ content: "Enviando", key })
      setTimeout(() => {
        message.error({
          content: "Ocurrio un error, intente enviar nuevamente",
          key,
          duration: 3,
        })
      }, 1000)
    }
  }*/}

  return (
    <Container>

      <FirstBlock>
      <div className="content-address">
        <div className="texthead">¡Conversemos!</div>

                <div className="boxw">
                <img src="/images/Contact/mail.svg"/>
                <a href="mailto:{data.email}">{data.email}</a>
                </div>

                <div className="boxw">
                <img src="/images/Contact/phone.svg"/>
                <a href={`tel:+51${data.phone}`}>{data.phone}</a>
                </div>


                <div className="boxw">
                <img src="/images/Contact/whatsapp.svg"/>        
                <a href={data?.whatsapp_link} target="_blank">Chat WhatsApp</a>
                </div>

                <div className="box">
                <img src="/images/Contact/gps.svg"/>
                {data?.ubication}
                </div>

        <div className="content-siguenos">
            <div className="pretext">¡Síguenos!</div>
              <ContentLink
                className="link"
                to={data?.facebook_link}
                target="_blank"
              >
                <img className="img2" src="/images/Contact/fb.svg" alt="facebook de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>

              <ContentLink
                className="link"
                to={data?.instagram_link}
                target="_blank"
              >
                <img className="img2" src="/images/Contact/ig.svg" alt="instagram de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>

              <ContentLink
                className="link"
                to={data?.tiktok_link}
                target="_blank"
              >
                <img className="img2" src="/images/Contact/tiktok.svg" alt="tiktok de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>

              <ContentLink
                className="link"
                to={data?.linkedin_link}
                target="_blank"
              >
                <img className="img2" src="/images/Contact/linkedin.svg" alt="linkedin de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>

              <ContentLink
                className="link"
                to={data?.youtube_link}
                target="_blank"
              >
                <img className="img2" src="/images/Contact/youtube.svg" alt="youtube de empresa dedicada a educar a especialistas 3d" />
              </ContentLink>
            
        </div>
      </div>

      <div className="content-form">
        <div className="texthead">Envíanos un mensaje</div>
        
        <form className="form">

        <div className="content-text">
            <label for="name"></label>
            <input
              placeholder="Nombre completo"
              ref={nameref}
              type="text"
              id="name"
              onChange={(e)=>{setName(e.target.value)}}
            />
          </div>

          <div className="list"> {/*?????????????????????????????*/}
          {/*<select name="drinks" required>
      <option value="" disabled selected hidden>Seleccione un curso</option>
      
      {courses.map(item => {
        return (
          <option value={item.title}>{item.title}</option>
        )
      })

      }
    
    </select>*/}
      <Selectstyled
      placeholder="Seleccione un curso"
      value={curso}
      bordered={false}
      onChange={handleChange}
      options={
        courses.map(item => {
          return({value:item.title ,label: item.title })
        })
          }
    />
            
          </div>

          <div className="content-text"> {/*?????????????????????????????*/}
            <label for="number"></label>
            <input
              placeholder="Número de WhatsApp"
              ref={whatsappref}
              type="text"
              id="cellphone"
              onChange={(e)=>{setCellphone(e.target.value)}}
            />
          </div>

        <div className="content-text">
            <label for="subject"></label>
            <textarea
              placeholder="Mensaje (opcional)"
              ref={mensajeref}
              type="text"
              id="body"
              onChange={(e)=>{setMessage(e.target.value)}}
            />
          </div>


          </form>

        <div className="centered"><Button02 onClick={() => submit()}>Enviar</Button02>
        { showLoading&&
       <div className="loading">Enviando...</div>}

       { showOk&&
       <div className="done">¡Enviado!</div>}

      </div>

        </div>

      </FirstBlock>

<SecondBlock>
          <div className="texthead">¡Ubícanos!</div>
          <iframe width="650" height="450" id="gmap_canvas" src="https://maps.google.com/maps?q=bolivar%201342%20,%20Lima%20,%20Peru&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
          </iframe>
          <div className="textquote">"Juntos alumbramos el camino a tu éxito."</div>
</SecondBlock>
 
 
    </Container>
  )
}

export default ContactFormInfo

import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getAboutUsPage, getGeneralInfo, getCourses, postForm } from "../../providers"

import { Hero4 } from "../../components/Hero4"
import { MainLayout } from "../../components/Layouts/MainLayout"
import ContactFormInfo from "../../components/Contact/ContactFormInfo"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`

const Contact = () => {
  const [image, setImage] = useState({})
  const [generalInfo, setGeneralInfo] = useState({})

  const [courses, setCourses] = useState([])

  
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        //const _aboutUs = await getAboutUsPage()
        const _generalInfo = await getGeneralInfo()
        setGeneralInfo(_generalInfo[0])
        setImage(_generalInfo[0].background_image)

        const _courses = await getCourses()
        setCourses(_courses)

      } catch (error) {
        console.log(error)
      }
    }
    fetchInfo()
  }, [])

  return (
    <MainLayout>
      <Hero4 backgroundImage={image} />
      <Container>
        <ContactFormInfo data={generalInfo} courses={courses}/>
      </Container>
    </MainLayout>
  )
}

export default Contact
